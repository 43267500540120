import { graphql } from 'gatsby';
export default from './tradition';

export const traditionQuery = graphql `
  query traditionENQuery {
    allContentfulTraditionEintrag(sort: {fields: contentfulid}, filter: {node_locale: {eq: "en" }}) {
      nodes {
        ...TraditionQueryFragment
      }
    }
  }
`
